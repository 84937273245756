<style>
.ivu-picker-confirm .ivu-btn-default {
  background-color: #333;
}
</style>
<template>
  <div>
    <Divider dashed><span class="divider-text">作业令录入</span></Divider>
    <Row class="m-t-10">
      <i-col span="16">
        <h4 class="workplatform-title p-b-5">作业令计划类型</h4>
        <RadioGroup
          type="button"
          size="small"
          v-model="actionType"
          @on-change="handleChangeActionType"
        >
          <Radio
            v-for="item in planTypeArray"
            :key="'actionType_'+item.value"
            :label="item.value"
          >{{item.name}}</Radio>
        </RadioGroup>
      </i-col>
      <i-col
        span="8"
        class="text-right"
      >
        <Button
          type="primary"
          size="small"
          @click="handleChioceTemplate"
        >选择作业令模板</Button>
      </i-col>

    </Row>
    <div class="m-t-10">
      <h4 class="workplatform-title p-b-5"><span class="text-red p-r-5">*</span>作业令名称</h4>
      <Input
        v-model="actionDateModel.workdayName"
        size="small"
        placeholder="填写作业令名称"
      />
    </div>
    <div class="m-t-10">
      <h4 class="workplatform-title p-b-5"><span class="text-red p-r-5">*</span>作业令日期</h4>
      <DatePicker
        transfer
        :clearable="false"
        type="date"
        size="small"
        placeholder="选择作业令日期"
        style="width: 100%"
        v-model="actionDateModel.workday"
        @on-change="handleChangeWorkday"
      ></DatePicker>
    </div>
    <div class="m-t-10">
      <h4 class="workplatform-title p-b-5"><span class="text-red p-r-5">*</span>作业起止时间</h4>
      <Row :gutter="8">
        <i-col span="12">
          <div>开始时间</div>
          <div>
            <DatePicker
              transfer
              :clearable="false"
              :disabled="sTimeDisabled"
              :options="sTimeOptions"
              type="datetime"
              format="yyyy-MM-dd HH:mm"
              size="small"
              placeholder="选择开始时间"
              style="width: 100%"
              v-model="startTime"
            ></DatePicker>
          </div>
        </i-col>
        <i-col span="12">
          <div>结束时间</div>
          <div>
            <DatePicker
              transfer
              :clearable="false"
              :disabled="eTimeDisabled"
              :options="eTimeOptions"
              type="datetime"
              format="yyyy-MM-dd HH:mm"
              size="small"
              placeholder="选择结束时间"
              style="width: 100%"
              v-model="endTime"
            ></DatePicker>
          </div>
        </i-col>
      </Row>
    </div>
    <div
      class="m-t-10"
      v-if="actionType===1"
    >
      <h4 class="workplatform-title p-b-5"><span class="text-red p-r-5">*</span>作业类型（多选）</h4>
      <CheckboxGroup
        v-model="actionDateModel.dayType"
        size="small"
        @on-change="handleChangeDaytype"
      >
        <Checkbox
          v-for="item in taskTypeArray"
          :key="'dayType_'+item.value"
          :label="item.value"
        >{{item.name}}</Checkbox>
      </CheckboxGroup>
    </div>
    <div class="m-t-10">
      <h4 class="workplatform-title p-b-5"><span class="text-red p-r-5">*</span>施工负责人</h4>
      <Select
        v-model="actionDateModel.executeUserId"
        placeholder="请选择施工负责人"
        size="small"
        :clearable="false"
        @on-change="handleChangeWorker"
      >
        <Option
          v-for="item in workerArray"
          :key="'userId_'+item.userId"
          :value="item.userId"
        >{{item.name}}</Option>
      </Select>
    </div>
    <div class="m-t-10">
      <h4 class="workplatform-title p-b-5"><span class="text-red p-r-5">*</span>作业线路（单选）</h4>
      <div
        v-for="item in assetList"
        :key="'assetId_'+item.id"
        @click="handleChangeAsset(item.id)"
        class="m-b-2"
        :class="chooseAssetId===item.id?'workplatform-radio-button-active':'workplatform-radio-button'"
      >{{item.name}}</div>
    </div>
    <div
      class="m-t-10"
      v-show="assetList.length>0"
    >
      <h4 class="workplatform-title p-b-5"><span class="text-red p-r-5">*</span>作业站点及场段（多选）</h4>
      <div class="m-b-10">
        <p class="text-orange text-14">提示：</p>
        <p class="text-14">当前选择的作业日期已设置过的站点，无法进行再次选择</p>
      </div>
      <div class="m-b-5">
        <Button
          type="primary"
          size="small"
          @click="handleSelectAll"
        >{{btnText}}</Button>
      </div>
      <Button
        v-for="station in stationList"
        :key="'stationId_'+station.id"
        :disabled="existStationArray.includes(station.id)"
        class="m-b-5 m-r-5"
        size="small"
        @click="changeCheckStations(station.id)"
        :class="checkedStations.includes(station.id)?'btn-black-actived':'btn-black'"
      >{{station.name}}</Button>
    </div>
    <div class="m-t-10">
      <h4 class="workplatform-title p-b-5">设置模板</h4>
      <div>
        <Checkbox v-model="actionDateModel.saveWorkday">将该作业令设置为模板</Checkbox>
      </div>
    </div>
    <div class="m-t-10">
      <h4 class="workplatform-title p-b-5">备注</h4>
      <div>
        <Input
          v-model="actionDateModel.remark"
          type="textarea"
          placeholder="请填写备注"
        />
      </div>
    </div>
    <div class="m-t-10">
      <h4 class="p-b-5">附件上传</h4>
      <Upload
        multiple
        :before-upload="handleUpload"
        accept=".png,.jpg,.jpeg,.doc,.docx,.xls,.xlsx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        :data="actionDateModel"
        type="drag"
        action
      >
        <div style="padding: 5px 0">
          <Icon
            type="ios-cloud-upload"
            size="40"
            style="color: #3399ff"
          ></Icon>
          <p>选择上传文件</p>
        </div>
      </Upload>
      <p class="text-orange">提示：</p>
      <p>仅只支持JPG，PNG，Excel，Word，PDF。</p>
      <Row v-show="fileList.length">
        <i-col span="18">
          <b>文件名称</b>
        </i-col>
        <i-col
          span="6"
          class="text-center"
        >
          <b>操作</b>
        </i-col>
      </Row>
      <hr v-show="fileList.length" />
      <Row
        v-for="(file,fileIndex) in fileList"
        :key="fileIndex"
        v-show="fileList.length"
      >
        <i-col span="18">{{file.name.length>20?file.name.substring(0,19)+'...':file.name}}</i-col>
        <i-col
          span="6"
          class="text-center"
        >
          <a
            class="delete"
            @click="handleRemove(file)"
          >删除</a>
        </i-col>
        <i-col span="24">
          <Divider
            dashed
            style="margin:5px 0"
          />
        </i-col>
      </Row>
    </div>
    <div class="m-t-10 text-right">
      <Button
        type="success"
        size="small"
        :disabled="btnDisabled"
        :loading="btnSubmitLoading"
        @click="handleSubmit"
      >发起录入</Button>
    </div>
    <Modal
      v-model="historyModal"
      :mask-closable="false"
      width="400"
    >
      <p
        slot="header"
        class="text-center"
      >选择作业令模板</p>
      <div>
        <p class="m-b-10"><span class="text-orange">说明:</span> 选择指定的作业令模板，可进行快速填充新的作业令录入。</p>
        <div class="m-b-10">
          <div class="m-b-10">选择作业令模板</div>
          <Select
            v-model="templateName"
            :clearable="true"
            filterable
            placeholder="请选择作业模板（可输入关键字搜索）"
            size="small"
            @on-change="handleChioceHistory"
          >
            <Option
              v-for="item in actionDateTemplateArray"
              :key="'temp_'+item.workday"
              :value="item.workdayName+'_'+item.workday"
            >{{item.workdayName+' ('+item.workday+')'}}</Option>
          </Select>
        </div>
        <div
          class="m-b-10"
          v-show="tableList.length"
        >
          <div class="m-b-10">选择作业类型</div>
          <CheckboxGroup
            v-model="dayType"
            size="small"
            @on-change="handleChangeHistoryDT"
          >
            <Checkbox
              v-for="item in tableList"
              :disabled="item.disabled"
              :key="'dType_'+item.dayType"
              :label="item.dayType"
            >{{item.dayTypeName}} ({{item.stationCount}})</Checkbox>
          </CheckboxGroup>
        </div>
        <div v-show="assetArray.length">
          <div class="m-b-10">选择作业线路（单选）</div>
          <RadioGroup
            v-model="hAssetId"
            type="button"
            size="small"
          >
            <Radio
              v-for="item in assetArray"
              :key="'hAssetId'+item.assetId"
              :label="item.assetId"
            >{{item.assetName}}</Radio>
          </RadioGroup>
        </div>
      </div>
      <div slot="footer">
        <Button
          type="text"
          class="m-r-5"
          @click="handleCancelHistoryModal"
        >取消</Button>
        <Button
          type="primary"
          @click="handleConfirmHistory"
          :disabled="btnHistroyConfirm"
        >确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import axios from 'axios'
import { getStorage } from '@/utils/storage'
import { ParseDate, ParseDateTime } from '@/utils/dateFormat'

// import { getWorkdayList, getStationList, setWorkday, deleteWorkday } from '@/api/msp/workday'
// import { getStationList } from '@/api/msp/workday'
import { getRimStationByAssetIds } from '@/api/rim/station'
import { getMaintenanceAssetList } from '@/api/rim/asset'
import { getUserinfoPage } from '@/api/os/user'
import { getWorkdayNamelist } from '@/api/msp/workday'

export default {
  data () {
    return {
      actionDateModel: {
        workday: '',
        startTime: '',
        endTime: '',
        dayType: [],
        executeCompanyId: null,
        executeUserId: null,
        remark: '',
        workdayName: '',
        saveWorkday: false,
        files: null
      },
      startTime: '',
      endTime: '',
      sTimeOptions: {},
      eTimeOptions: {},
      planTypeArray: [
        { value: 1, name: '作业计划' },
        { value: 2, name: '行车计划' }
      ],
      taskTypeArray: [
        { value: 1, name: '上下刊' },
        { value: 2, name: '维修' }
      ],
      actionType: 1,
      workerArray: [],
      assetList: [], // 所有可用线路
      // selectedAssetIds: [],
      chooseAssetId: 0,
      allStationList: [], // 所有可用站点
      stationList: [], //
      checkedStations: [],
      fileList: [],
      chooseDate: ParseDate(new Date()),
      btnSubmitLoading: false,
      existStationArray: [],
      historyModal: false,
      // 历史作业令选择参数
      templateName: '',
      query: {
        workday: '',
        keyword: '',
        workdayName: '',
        pageNumber: 1,
        pageSize: 15
      },
      tableList: [],
      hTimeOptions: {
        disabledDate (date) {
          return date && date.valueOf() > new Date() - 86400000
        }
      },
      dayType: [],
      assetArray: [],
      hAssetId: null,
      workdayTaskArray: [],
      actionDateTemplateArray: [] // 作业令模板数据
    }
  },
  computed: {
    sTimeDisabled () {
      return !this.actionDateModel.workday
    },
    eTimeDisabled () {
      return !this.startTime
    },
    btnDisabled () {
      if (!this.actionDateModel.workday || !this.startTime || !this.endTime || !this.actionDateModel.executeUserId || !this.chooseAssetId || this.checkedStations.length === 0 || this.actionDateModel.dayType.length === 0) {
        return true
      } else {
        return false
      }
    },
    btnText () {
      if (this.checkedStations.length === this.stationList.length) {
        return '取消全选'
      } else {
        return '全选'
      }
    },
    btnHistroyConfirm () {
      if (this.dayType.length && this.templateName && this.hAssetId) {
        return false
      } else {
        return true
      }
    }
  },
  created () {
    this.loadNextAllAssetList()
    this.getWorkerData()
  },
  methods: {
    handleChangeWorkday () {
      const that = this
      that.startTime = ''
      that.endTime = ''
      that.sTimeOptions = {
        disabledDate (date) {
          return date && date.valueOf() < new Date(that.actionDateModel.workday).valueOf()
        }
      }
      that.eTimeOptions = {
        disabledDate (date) {
          return date && date.valueOf() < new Date(that.actionDateModel.workday).valueOf()
        }
      }
      that.getExistStationData()
    },
    handleChangeDaytype () {
      this.getExistStationData()
    },
    /**
     * 获取以设置的站点信息
     */
    getExistStationData () {
      const params = {
        pageNumber: 1,
        pageSize: 200,
        workday: ParseDate(this.actionDateModel.workday),
        dayTypes: JSON.stringify(this.actionDateModel.dayType)
      }
      if (new Date(params.workday + ' 18:00:00').valueOf() > new Date().valueOf()) {
        this.$store.dispatch('getActionDateDetailData', params).then(res => {
          if (res && !res.errcode) {
            this.existStationArray = []
            if (res.list && res.list.length) {
              this.existStationArray = res.list.map(x => x.stationId)
              // 判断当前作业日期已设置过的站点是否与选择的站点有重合
              if (this.existStationArray.some(x => this.checkedStations.includes(x))) {
                this.checkedStations = []
                this.$Message.error({
                  duration: 6,
                  background: true,
                  content: '当前作业日期已存在设置站点，请选择未设置过的站点进行操作。'
                })
              }
            }
          } else {
            this.existStationArray = []
          }
        })
      }
    },
    getWorkerData () {
      const query = {
        pageNumber: 1,
        pageSize: 100,
        companyId: this.$store.getters.token.userInfo.publisherId, // 租户公司id
        child: true,
        roles: 'ROLE_M_MANAGER'
      }
      getUserinfoPage(query).then(res => {
        if (res && !res.errcode) {
          this.workerArray = res.list
        } else {
          this.workerArray = []
        }
        this.$store.commit('set_worker_array', this.workerArray)
      })
    },
    handleChangeWorker () {
      this.actionDateModel.executeCompanyId = this.actionDateModel.executeUserId ? this.workerArray.find(x => x.userId === this.actionDateModel.executeUserId).companyId : null
    },
    loadNextAllAssetList () {
      getMaintenanceAssetList().then(res => {
        this.assetList = res.map(x => {
          // x.count = 0
          return x
        })
        this.$store.commit('set_asset_array', this.assetList)

        // 加载全部的站点清单
        const query = {
          assetIds: JSON.stringify(res.map(x => x.id)),
          excludeCd: false
        }
        getRimStationByAssetIds(query).then(stations => {
          this.allStationList = stations
          this.$store.commit('set_all_station_array', this.allStationList)
          this.handleChangeAsset(res[0].id)
          // this.updateMap([])
        })
      })
    },
    handleChangeAsset (assetId) {
      // 切换资产，清空当前已选择站点数据
      if (this.chooseAssetId !== assetId) {
        this.checkedStations = []
      }
      this.chooseAssetId = assetId
      this.stationList = this.allStationList.filter(x => x.assetId === this.chooseAssetId)
    },
    changeCheckStations (stationId) {
      if (this.checkedStations.includes(stationId)) {
        const index = this.checkedStations.indexOf(stationId)
        this.checkedStations.splice(index, 1)
      } else {
        this.checkedStations.push(stationId)
      }

      // 刷新地图和报表的站点显示
      const showStations = []
      this.checkedStations.forEach(element => {
        const stationInfo = this.allStationList.find(x => x.id === element)
        showStations.push({
          id: stationInfo.id,
          name: stationInfo.name,
          assetId: stationInfo.assetId,
          assetName: '',
          quantity: 0,
          positionQuantityList: []
        })
      })
      this.updateMap(showStations)
    },
    updateMap (stations) {
      this.$emit('on-show-mapStations', stations, this.isEdit, this.chooseDate)
    },
    handleChangeActionType () {
      this.actionDateModel.dayType = this.actionType === 2 ? [3] : []
      this.getExistStationData()
    },
    /**
     * 全选站点
     */
    handleSelectAll () {
      if (this.checkedStations.length < this.stationList.length) {
        this.checkedStations = this.stationList.map(x => x.id)
      } else {
        this.checkedStations = []
      }
    },
    handleUpload (file) {
      if (!this.fileList.some(x => x.name === file.name)) {
        this.fileList.push(file)
      }
      return false
    },
    handleRemove (file) {
      this.fileList.splice(this.fileList.indexOf(file), 1)
    },
    handleSubmit () {
      if (!this.actionDateModel.workdayName) {
        this.$Notice.warning({ desc: '请选择作业令名称' })
        return false
      }
      if (!this.actionDateModel.workday) {
        this.$Notice.warning({ desc: '请选择作业令日期' })
        return false
      }
      if (!this.startTime || !this.endTime) {
        this.$Notice.warning({ desc: '请选择起始时间' })
        return false
      }
      if (this.actionDateModel.dayType.length === 0) {
        this.$Notice.warning({ desc: '请选择作业类型' })
        return false
      }
      if (!this.actionDateModel.executeUserId) {
        this.$Notice.warning({ desc: '请选择施工负责人' })
        return false
      }
      if (!this.chooseAssetId) {
        this.$Notice.warning({ desc: '请选择作业线路' })
        return false
      }
      if (this.checkedStations.length === 0) {
        this.$Notice.warning({ desc: '请选择作业站点' })
        return false
      }
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要发起作业令录入？',
        onOk: () => {
          this.btnSubmitLoading = true

          this.actionDateModel.startTime = ParseDateTime(this.startTime)
          this.actionDateModel.endTime = ParseDateTime(this.endTime)

          const postData = new FormData()
          postData.append('assetId', this.chooseAssetId)
          postData.append('dayType', JSON.stringify(this.actionDateModel.dayType))
          postData.append('startTime', this.actionDateModel.startTime)
          postData.append('endTime', this.actionDateModel.endTime)
          postData.append('executeUserId', this.actionDateModel.executeUserId)
          postData.append('executeCompanyId', this.actionDateModel.executeCompanyId)
          postData.append('workday', ParseDate(this.actionDateModel.workday))
          postData.append('stationIds', JSON.stringify(this.checkedStations))
          postData.append('remark', this.actionDateModel.remark)
          postData.append('workdayName', this.actionDateModel.workdayName)
          postData.append('saveWorkday', this.actionDateModel.saveWorkday)
          if (this.fileList && this.fileList.length) {
            this.fileList.forEach((file) => {
              postData.append('files', file)
            })
          } else {
            postData.append('files', null)
          }
          axios.post(
            process.env.VUE_APP_API_URL_V2 +
            '/ooh-msp/v2/workday/addworkday',
            postData,
            {
              timeout: 15000, // 请求超时
              headers: {
                'Content-Type': 'multipart/form-data',
                'ooh-auth': getStorage('ooh-token')
              },
              withCredentials: true
            }
          ).then(res => {
            this.btnSubmitLoading = false
            if (res.data && res.data.errcode === 0) {
              this.actionType = 1
              this.actionDateModel.workday = ''
              this.startTime = ''
              this.endTime = ''
              this.actionDateModel.dayType = []
              this.actionDateModel.executeUserId = null
              this.actionDateModel.executeCompanyId = null
              this.checkedStations = []
              this.actionDateModel.remark = ''
              this.fileList = []
              this.actionDateModel.workdayName = ''
              this.actionDateModel.saveWorkday = false
              this.$Notice.success({ desc: '操作成功' })
              this.$emit('loadBottomData')
            }
          })
        }
      })
    },
    handleChioceTemplate () {
      this.historyModal = true
      this.getActiondateTemplate()
    },
    /**
     * 获取作业令模板数据
     */
    getActiondateTemplate () {
      getWorkdayNamelist().then(res => {
        if (res && !res.errcode) {
          this.actionDateTemplateArray = res
        }
      })
    },
    /**
     * 选择历史作业令
     */
    handleChioceHistory () {
      this.dayType = []
      this.hAssetId = null
      this.tableList = []
      this.assetArray = []
      this.workdayTaskArray = []

      if (this.templateName) {
        const workdayTemplate = this.templateName.split('_')
        if (workdayTemplate.length) {
          this.query.workdayName = workdayTemplate[0]
          this.query.workday = workdayTemplate[1]
        }

        this.$store.dispatch('getWorkdayData', this.query).then(res => {
          if (res && !res.errcode) {
            if (res.list && res.list.length) {
              this.tableList = res.list[0].statisticList
            }
            this.query.pageNumber = res.pageNumber
          } else {
            this.tableList = []
            this.query.pageNumber = 1
          }
        })
      }
    },
    handleChangeHistoryDT () {
      this.assetArray = []
      this.workdayTaskArray = []
      this.tableList.forEach(item => {
        item.disabled = false
        if ((this.dayType.some(x => [1, 2].includes(x)) && item.dayType === 3) || (this.dayType.includes(3) && [1, 2].includes(item.dayType))) {
          item.disabled = true
        }
      })

      if (this.dayType.length === 0) {
        return false
      }
      const params = {
        pageNumber: 1,
        pageSize: 200,
        workday: this.query.workday,
        assetId: null,
        dayTypes: JSON.stringify(this.dayType)
      }
      this.$store.dispatch('getActionDateDetailData', params).then(res => {
        if (res && res.list.length) {
          this.workdayTaskArray = res.list
          this.workdayTaskArray.forEach(item => {
            if (!this.assetArray.some(x => x.assetId === item.assetId)) {
              const asset = {
                assetId: item.assetId,
                assetName: item.assetName
              }
              this.assetArray.push(asset)
            }
          })
        } else {
          this.workdayTaskArray = []
          this.assetArray = []
        }
      })
    },
    handleCancelHistoryModal () {
      this.query.workday = ''
      this.query.workdayName = ''
      this.templateName = ''
      this.dayType = []
      this.hAssetId = null
      this.tableList = []
      this.assetArray = []
      this.workdayTaskArray = []
      this.historyModal = false
    },
    handleConfirmHistory () {
      if (!this.templateName || this.dayType.length === 0 || !this.hAssetId) {
        this.$Notice.error({
          title: '操作提示',
          content: '违法操作，请完整填写内容'
        })
        return false
      }
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定选择当前作业令数据进行新作业令内容填充，请记得更改作业日期？',
        onOk: () => {
          const availableTask = this.workdayTaskArray.filter(x => x.assetId === this.hAssetId)
          this.actionType = this.dayType.some(x => [1, 2].includes(x)) ? 1 : 2
          this.actionDateModel.workday = this.query.workday
          this.actionDateModel.dayType = this.dayType
          this.actionDateModel.workdayName = availableTask[0].workdayName
          this.actionDateModel.executeUserId = availableTask[0].executeUserId
          this.actionDateModel.executeCompanyId = availableTask[0].executeCompanyId
          // this.startTime = availableTask[0].startTime
          // this.endTime = availableTask[0].endTime
          this.chooseAssetId = this.hAssetId
          this.checkedStations = availableTask.map(x => x.stationId)
          this.handleChangeAsset(this.chooseAssetId)

          this.handleCancelHistoryModal()
        }
      })
    }
  }
}
</script>
